import React, { useEffect, useMemo, useState } from 'react';
import { Select, SelectOption } from '@components/MUI/Select';
import { Form, FormikBag, useFormikContext, withFormik } from 'formik';
import { object, string } from 'yup';
import { Vacancy } from '@store/vacancy/contracts';
import { Link } from 'react-router-dom';
import { Button } from '@components/MUI/Button';
import styles from './CreateOffer.module.scss';
import { useGetMyVacanciesQuery } from '@store/vacancy/vacancy.slice';

export type CreateOfferValue = {
  vacancyId: string;
};

export type CreateOfferFormProps = {
  submit: (
    value: CreateOfferValue,
    form: FormikBag<CreateOfferFormProps, CreateOfferValue>,
  ) => void;
  cancel: () => void;
};

const createOfferSchema = object({
  vacancyId: string().required('Поле обязательно для заполнения'),
});

export const CreateOffer: React.FC = () => {
  const form = useFormikContext<CreateOfferValue>();
  const cancel = form.getFieldProps('cancel').value;
  const [currentVacancy, setCurrentVacancy] = useState<Vacancy | null | undefined>(null);
  const { data: vacancies } = useGetMyVacanciesQuery({ start: 0, limit: 50 });

  const vacanciesSelectOptions = useMemo(() => {
    if (vacancies?.data) {
      const vacanciesSelectOptions = vacancies?.data?.map((vacancy) => {
        const vacancySelectOption: SelectOption = {
          value: vacancy.id,
          label: vacancy.name,
        };
        return vacancySelectOption;
      });
      return vacanciesSelectOptions;
    }
    return [];
  }, [vacancies]);

  useEffect(() => {
    const selectedVacancyId = form.values.vacancyId;
    const selectedVacancy = vacancies?.data?.find((vacancy) => vacancy.id === selectedVacancyId);
    setCurrentVacancy(selectedVacancy || null);
  }, [form.values.vacancyId, vacancies]);

  return (
    <Form onSubmit={form.handleSubmit}>
      <Select
        name="vacancyId"
        values={vacanciesSelectOptions}
        isOutlined={true}
        label={vacanciesSelectOptions.length > 0 ? 'Вакансия*' : 'Нет созданных вакансий'}
        disabled={vacanciesSelectOptions?.length === 0}
        className={styles.select__vacancy}
      />
      <div className={styles.offer__team}>
        <div className={styles.offer__team__label}>Команда</div>
        <div className={styles.offer__team__value}>
          <Link to={`/teams/${currentVacancy?.teamId}/members`} onClick={cancel}>
            {currentVacancy?.teamName}
          </Link>
        </div>
      </div>
      <div className={styles.offer__direction}>
        <div className={styles.offer__direction__label}>Направление</div>
        <div className={styles.offer__direction__value}>{currentVacancy?.positionName}</div>
      </div>
      <div className={styles.offer__stack}>
        <div className={styles.offer__stack__label}>Стек</div>
        <div className={styles.offer__stack__value}>{currentVacancy?.stack}</div>
      </div>
      <div className={styles.offer__payment}>
        <div className={styles.offer__payment__label}>Ставка</div>
        <div className={styles.offer__payment__value}>
          {currentVacancy?.payment
            ? `${currentVacancy?.payment} ${currentVacancy?.currencyName}`
            : ''}
        </div>
      </div>
      <div className="d-flex justify-content-end mt-5">
        <Button className={styles.button__cancel} onClick={cancel}>
          Отменить
        </Button>
        <Button
          disabled={!form.isValid}
          className={styles.button__submit}
          variant="contained"
          type="submit"
          disableElevation={true}>
          Отправить
        </Button>
      </div>
    </Form>
  );
};

export const CreateOfferForm = withFormik<CreateOfferFormProps, CreateOfferValue>({
  displayName: 'CreateOfferForm',
  mapPropsToValues: (props) => {
    return {
      cancel: props.cancel,
      vacancyId: '',
    };
  },
  isInitialValid: false,
  enableReinitialize: true,
  validationSchema: createOfferSchema,
  validateOnMount: false,
  validateOnChange: true,
  handleSubmit: (values, formikBag) => {
    formikBag.props.submit(values, formikBag);
  },
})(CreateOffer);
