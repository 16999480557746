import React from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { Manager, RequestItem } from '@store/requests/contracts';
import { selectCurrentUserId } from '@store/user/user.slice';
import { Link } from 'react-router-dom';
import { closeModal } from '@store/ui/ui.slice';
import { AdaptiveActionButtons } from '@components/adaptives/AdaptiveActionButtons';
import { useNavigateToUserChat } from '@hooks/useNavigateToUserChat';
import { useAcceptManagerMutation, useDenyManagerMutation } from '@store/requests/requests.slice';

type RenderManagersProps = {
  managers: Manager[];
  request: RequestItem;
};

export const RenderManagers: React.FC<RenderManagersProps> = ({ managers, request }) => {
  const myId = useAppSelector(selectCurrentUserId);
  const dispatch = useAppDispatch();
  const createChat = useNavigateToUserChat();
  const [denyManager] = useDenyManagerMutation();
  const [acceptManager] = useAcceptManagerMutation();

  const handleApprove = (request: RequestItem, manager: Manager) => {
    acceptManager({ id: request.id, managerId: manager.managerId }).finally(() =>
      dispatch(closeModal()),
    );
  };

  const handleClose = (request: RequestItem, manager: Manager) => {
    denyManager({ id: request.id, managerId: manager.managerId }).finally(() =>
      dispatch(closeModal()),
    );
  };

  const handleSendMessage = (manager: Manager) => {
    createChat(manager.managerId);
  };

  return (
    <>
      {managers && managers.length > 0 ? (
        managers.map((manager: Manager) => (
          <div key={manager.managerId}>
            <div
              className="d-flex pe-4"
              style={{ justifyContent: 'space-between', marginBottom: '0.5rem' }}>
              <Link to={`/userPage/${manager.managerId}`}>{manager.managerName}</Link>
              {myId !== manager.managerId ? (
                <AdaptiveActionButtons
                  isOnlySend={
                    request.statusName === 'На рассмотрении' &&
                    manager.statusName === 'Запрос от менеджера' &&
                    manager.managerId !== myId
                      ? false
                      : true
                  }
                  handleApprove={() => handleApprove(request, manager)}
                  handleClose={() => handleClose(request, manager)}
                  handleSendMessage={() => handleSendMessage(manager)}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        ))
      ) : (
        <p>-</p>
      )}
    </>
  );
};
