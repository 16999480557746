import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { closeModal } from '@store/ui/ui.slice';
import { Form, FormikBag, FormikContextType, useFormikContext, withFormik } from 'formik';
import * as Yup from 'yup';
import { SelectData, fromDictionaryToSelectOption } from '@components/ui/Select/Select';
import { useAppDispatch } from '@hooks/index';
import styles from './CreateVacancyModal.module.scss';
import { Select } from '@components/MUI/Select';
import { InputField } from '@components/MUI/InputField/InputField';
import { FormWrapper } from '@components/Form/Form';
import { Checkbox, FormControlLabel, SelectChangeEvent } from '@mui/material';
import { Button } from '@components/MUI/Button';
import { useDictionary } from '@hooks/useDictionary';
import { DictionaryName } from '@store/dictionary/contracts';
import { useLocation } from 'react-router-dom';
import { useGetTeamListQuery, useLazyGetAvailablePositionsQuery } from '@store/team/team.slice';
import { useLazySearchProfilesQuery } from '@store/user/user.slice';

export type CreateVacancyValue = {
  name: string;
  teamId: string;
  teamPositionId: string;
  directionId: string;
  stack: string;
  comment: string;
  payment: number;
  executorId?: string;
  open?: boolean;
  currencyId: string;
};

export type CreateVacancyProps = {
  value: CreateVacancyValue;
  submit: (
    value: CreateVacancyValue,
    form: FormikBag<CreateVacancyProps, CreateVacancyValue>,
  ) => void;
};

const createVacancySchema = Yup.object({
  name: Yup.string().required('Поле обязательно для заполнения'),
  teamId: Yup.string().required('Поле обязательно для заполнения'),
  teamPositionId: Yup.string().required('Поле обязательно для заполнения'),
  directionId: Yup.string().required('Поле обязательно для заполнения'),
  stack: Yup.string().required('Поле обязательно для заполнения'),
  comment: Yup.string(),
  payment: Yup.string()
    .required('Поле обязательно для заполнения')
    .matches(
      /^(100000000(\.0{1,2})?|([0-9]{1,8})(\.[0-9]{1,2})?)$/,
      'Введите число не меньше 0, не более 100000000 и не более 2 знаков после запятой',
    ),
  currencyId: Yup.string().required('Поле обязательно для заполнения'),
  open: Yup.boolean(),
  executorId: Yup.string()
    .nullable()
    .test('executor-or-open', 'Выберите исполнителя или разрешите отклики', function (value) {
      const { open } = this.parent;
      return open || value != null;
    }),
});

function useVacancyFormOptions(form: FormikContextType<CreateVacancyValue>): {
  teams: SelectData;
  roles: SelectData;
  executors: SelectData;
} {
  const [teamList, setTeamList] = useState<SelectData>({
    options: [],
    name: 'teamId',
    placeholder: 'Команда',
  });

  const [teamPosition, setTeamPosition] = useState<SelectData>({
    options: [],
    name: 'teamPositionId',
    placeholder: 'Роль',
  });

  const [executors, setExecutors] = useState<SelectData>({
    options: [],
    name: 'executorId',
    placeholder: 'Исполнитель',
  });

  const executorId = useDictionary(DictionaryName.ROLE, fromDictionaryToSelectOption).find(
    (role) => role.label === 'Исполнитель',
  )?.value;

  const { data: teams } = useGetTeamListQuery({
    start: 0,
    limit: 50,
    needFreePositions: true,
  });

  const [getAvailablePositions] = useLazyGetAvailablePositionsQuery();
  const [searchProfiles] = useLazySearchProfilesQuery();

  useEffect(() => {
    if (teams) {
      setTeamList((state) => {
        return {
          ...state,
          options: teams.data.map((team) => ({
            value: team.id,
            label: team.name,
          })),
        };
      });
    }
  }, [teams]);

  useEffect(() => {
    if (!form.values.teamId) {
      setTeamPosition((state) => {
        return {
          ...state,
          disabled: true,
        };
      });
      return;
    }
    getAvailablePositions({ id: form.values.teamId })
      .then((response) => {
        if (response.data) {
          const options = response?.data || [];
          setTeamPosition((state) => {
            return {
              ...state,
              options: options.map((position) => ({
                value: position.id,
                label: position.positionName,
              })),
              disabled: response?.data?.length === 0,
            };
          });
        }
      })
      .catch((error) => {
        // console.error(error)
      });
  }, [form.values.teamId]);

  useEffect(() => {
    searchProfiles({ query: '', roleId: executorId })
      .then((response) => {
        if (response?.data?.data) {
          setExecutors((state) => {
            return {
              ...state,
              options: response!.data!.data?.map((executor) => ({
                value: executor.id,
                label: executor.userName ? executor.userName : '',
              })),
              disabled: response?.data?.data?.length === 0,
            };
          });
        }
      })
      .catch((e) => {
        // console.error(e)
      });
  }, []);

  return {
    teams: teamList,
    roles: teamPosition,
    executors: executors,
  };
}

export const CreateVacancyModal: React.FC = () => {
  const directions = useDictionary(DictionaryName.DIRECTION, fromDictionaryToSelectOption);
  const createVacancyModalForm = useFormikContext<CreateVacancyValue>();
  createVacancyModalForm.validationSchema = createVacancySchema;
  const formOptions = useVacancyFormOptions(createVacancyModalForm);
  const currencies = useDictionary(DictionaryName.CURRENCY, fromDictionaryToSelectOption);
  const dispatch = useAppDispatch();
  const route = useLocation();
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (createVacancyModalForm.values.open) {
      createVacancyModalForm.setFieldValue('executorId', '');
    }
  }, [createVacancyModalForm.values.open]);

  const disableFields = useMemo(() => {
    if (route.pathname === `/teams/${createVacancyModalForm.values.teamId}/members`) return true;
    else return false;
  }, []);

  const cancel = useCallback(() => {
    dispatch(closeModal());
    setOpen(false);
  }, [dispatch]);

  return (
    <>
      <Modal
        open={open}
        onClose={cancel}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box
          className={styles.mainBox}
          sx={{ maxWidth: '480px', width: '100%', height: '750px', overflowY: 'auto' }}>
          <FormWrapper label={'Создание вакансии'}>
            <Form onSubmit={createVacancyModalForm.handleSubmit}>
              <InputField
                name="name"
                placeholder="Название вакансии"
                type="text"
                className={styles.name__field}
                style={{ marginTop: '1.5rem' }}
              />
              <Select
                values={formOptions?.teams?.options}
                name={formOptions?.teams?.name}
                label={
                  formOptions?.teams?.options?.length > 0
                    ? formOptions.teams.placeholder
                    : 'Команда и роли не cформированы или произошла ошибка'
                }
                onChange={(event: SelectChangeEvent) => {
                  createVacancyModalForm.setFieldValue('teamId', event.target.value);
                }}
                isOutlined={true}
                style={{ marginTop: '1.5rem' }}
                value={createVacancyModalForm?.values?.teamId}
                disabled={
                  formOptions?.teams?.options?.length === 0 ||
                  formOptions?.teams?.disabled ||
                  disableFields
                }
              />
              <Select
                name={formOptions?.roles?.name}
                values={formOptions?.roles?.options}
                label={formOptions?.roles?.placeholder}
                onChange={(event: SelectChangeEvent) => {
                  createVacancyModalForm.setFieldValue('teamPositionId', event.target.value);
                }}
                style={{ marginTop: '1.5rem' }}
                isOutlined={true}
                value={createVacancyModalForm?.values?.teamPositionId}
                disabled={formOptions?.roles?.disabled || disableFields}
              />
              <Select
                name="directionId"
                values={directions}
                label={'Направления'}
                onChange={(event: SelectChangeEvent) => {
                  createVacancyModalForm.setFieldValue('directionId', event.target.value);
                }}
                style={{ marginTop: '1.5rem' }}
                isOutlined={true}
                value={createVacancyModalForm?.values?.directionId}
              />
              {!createVacancyModalForm.values.open && (
                <Select
                  name={formOptions?.executors?.name}
                  values={formOptions?.executors?.options}
                  label={formOptions?.executors?.placeholder}
                  style={{ marginTop: '1.5rem' }}
                  isOutlined={true}
                  disabled={formOptions?.executors?.disabled || disableFields}
                />
              )}
              <InputField
                style={{ marginTop: '1.5rem' }}
                type="text"
                placeholder="Стек"
                name="stack"
                className={styles.stack__field}
              />
              <InputField
                style={{ marginTop: '1.5rem' }}
                placeholder="Ставка"
                className={styles.payment__field}
                name="payment"
                type="number"
              />
              <Select
                name="currencyId"
                values={currencies}
                isOutlined={true}
                label={'Валюта'}
                style={{ marginTop: '1.5rem' }}
                value={createVacancyModalForm?.values?.currencyId}
              />
              <InputField
                name="comment"
                placeholder="Комментарий"
                multiline={true}
                rows={4}
                type="text"
                className={styles.payment__field}
                style={{ marginTop: '1.5rem' }}
              />
              <FormControlLabel
                control={<Checkbox name="open" checked={createVacancyModalForm.values.open} />}
                label="Разрешить отклики другим пользователям"
                onChange={createVacancyModalForm.handleChange}
                className={styles.checkbox}
              />
              <div className="d-flex justify-content-end mt-5">
                <Button
                  style={{ color: '#78858B', padding: '10px 20px', marginRight: '0.5rem' }}
                  onClick={cancel}>
                  Отменить
                </Button>
                <Button
                  disabled={!createVacancyModalForm.isValid}
                  style={{ padding: '10px 20px' }}
                  variant="contained"
                  type="submit"
                  disableElevation={true}>
                  Создать
                </Button>
              </div>
            </Form>
          </FormWrapper>
        </Box>
      </Modal>
    </>
  );
};

export const CreateVacancyForm = withFormik<CreateVacancyProps, CreateVacancyValue>({
  displayName: 'CreateVacancyForm',
  mapPropsToValues: (props) => {
    return {
      name: props.value.name,
      teamId: props.value.teamId,
      teamPositionId: props.value.teamPositionId,
      directionId: props.value.directionId,
      stack: props.value.stack,
      comment: props.value.comment,
      payment: props.value.payment,
      currencyId: props.value.currencyId,
      executorId: props.value.executorId,
      open: props.value.open,
    };
  },
  isInitialValid: false,
  enableReinitialize: true,
  validationSchema: createVacancySchema,
  validateOnMount: false,
  validateOnChange: true,
  handleSubmit: (values, formikBag) => {
    formikBag.props.submit(values, formikBag);
  },
})(CreateVacancyModal);
