import { PaginationClass, ToDate } from '@store/common';
import { Offer } from '@store/offer/contracts';
import { Type } from 'class-transformer';
import { Dayjs } from 'dayjs';

export interface VacanciesGettingRequest {
  limit: number;
  offset: number;
}

export interface VacanciesGettingResponse {
  status: string;
  message: string;
  vacancies: Vacancy[];
}

export interface EditVacancyRequest {
  id: string;
  name: string;
  teamId: string;
  teamPositionId: string;
  directionId: string;
  stack: string;
  comment: string;
  payment: number;
  isOpen: boolean;
}

export interface EditVacancyResponse {
  success: boolean;
  id: string;
}

export class Vacancy {
  id!: string;
  @ToDate('DD.MM.YYYY HH:mm')
  created!: Dayjs;
  @ToDate('DD.MM.YYYY HH:mm')
  changed!: Dayjs;
  favorite!: boolean;
  name!: string;
  teamId!: string;
  teamName!: string;
  positionId!: string;
  directionId!: string;
  directionName!: string;
  currencyId!: string;
  currencyName!: string;
  positionName!: string;
  stack!: string;
  comment!: string;
  payment!: number;
  @Type(() => Author)
  author!: Author;
  @Type(() => Offer)
  offers!: Offer[];
  statusName!: string;
  statusId!: string;
  isOpen!: boolean;
}

export class VacancyResponse extends PaginationClass {
  @Type(() => Vacancy)
  data!: Vacancy[];
  recordsTotal!: number;
  recordsFiltered!: number;
}

export interface CreatingVacancyRequest {
  name: string;
  teamId: string;
  teamPositionId: string;
  directionId: string;
  stack: string;
  comment: string;
  payment: number;
  executorId?: string;
  currencyId: string;
  open?: boolean;
}

export interface CreatingVacancyResponse {
  success: boolean;
  id: string;
}

export class GetMyVacanciesResponse extends PaginationClass {
  @Type(() => Vacancy)
  data!: Vacancy[];
  recordsTotal!: number;
  recordsFiltered!: number;
}

export class GetVacanciesResponse extends PaginationClass {
  @Type(() => Vacancy)
  data!: Vacancy[];
}

export class GetMyOffersResponse extends PaginationClass {
  @Type(() => Vacancy)
  data!: Vacancy[];
}

export class Author {
  id!: string | null;
  @ToDate('DD.MM.YYYY HH:mm')
  created!: Dayjs;
  @ToDate('DD.MM.YYYY HH:mm')
  changed!: Dayjs;
  userId!: string;
  userName!: string | null;
  storedFileId!: string | null;
}
